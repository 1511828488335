export default {
    "kt": "Order created",
    "ssdt": "Wrong number",
    "sdttb": "Cant be reached",
    "kknm": "No answer",
    "k": "Other",
    "ht": "Confirmed",
    "h": "Refused"
}

